var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"collection","fluid":"","tag":"section"}},[_c('loader',{attrs:{"active":_vm.loaderActive}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","lg":""}},[_c('base-material-stats-card',{attrs:{"color":"primary","icon":"mdi-bag-carry-on-check","title":"Total ITD","value":_vm.responsePayload.total ? _vm.responsePayload.total.toString() : '0'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"red","icon":"mdi-block-helper","title":"Failed ITD","value":_vm.responsePayload.failedItd
						? _vm.responsePayload.failedItd.toString()
						: '0'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"success","icon":"mdi-check-all","title":"Successful ITD","value":_vm.responsePayload.successfulItd
						? _vm.responsePayload.successfulItd.toString()
						: '0'}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('base-material-stats-card',{attrs:{"color":"orange","icon":"mdi-update","title":"Pending Txns","value":_vm.responsePayload.pending ? _vm.responsePayload.pending.toString() : '0'}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"rows":"12","justify":"end"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-dark lightgray--text"},[_vm._v(" Transaction List "),_c('button',{staticClass:"float-right"},[_vm._v("Download")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","color":"lightgray"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}])},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loaderActive,"search":_vm.search,"item-key":"id","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); },"dblclick:row":function($event){return _vm.editItem(_vm.item)}},scopedSlots:_vm._u([{key:"item.status.name",fn:function(ref){
						var item = ref.item;
return [_c('div',{class:{
									'grey--text font-weight-normal': item.status.id === 4,
									'orange--text font-weight-normal': item.status.id === 3,
									'red--text font-weight-normal': item.status.id === 2,
									'green--text font-weight-normal': item.status.id === 1,
								}},[_vm._v(" "+_vm._s(item.status.name)+" ")])]}},{key:"item.amount",fn:function(ref){
								var item = ref.item;
return [_c('div',{class:{
									'grey--text font-weight-regular': item.status.id === 4,
									'orange--text font-weight-regular': item.status.id === 3,
									'red--text font-weight-regular': item.status.id === 2,
									'green--text font-weight-regular': item.status.id === 1,
								}},[_vm._v(" K"+_vm._s(item.amount)+" ")])]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }